import React from 'react';
import {createRoot} from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {CookiesProvider} from 'react-cookie';
import ReactGA from 'react-ga4';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import './local/i18n';
import App from 'navigation/App';

ReactGA.initialize('G-D0KDDTTLEC');
ReactGA.send('pageview');

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <CookiesProvider>
                <App />
            </CookiesProvider>
        </BrowserRouter>
    </React.StrictMode>,
);
reportWebVitals();
