import {useTranslation} from 'react-i18next';
import useInput from 'hooks/use-input';
import {Fragment, useState} from 'react';
import {NavLink} from 'react-router-dom';

import './Form.css';

const Form = (props) => {
    const {t} = useTranslation('global');

    const {
        value: nameValue,
        isValid: nameIsValid,
        hasError: nameHasError,
        valueChangeHandler: nameChangedHandler,
        inputBlurHandler: nameBlurHandler,
        reset: resetName,
    } = useInput((value) => value.trim() !== '');

    const {
        value: emailValue,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
        reset: resetEmail,
    } = useInput((value) => value.includes('@'));

    const {
        value: subjectValue,
        isValid: subjectIsValid,
        hasError: subjectHasError,
        valueChangeHandler: subjectChangeHandler,
        inputBlurHandler: subjectBlurHandler,
        reset: resetsubject,
    } = useInput((value) => value.trim() !== '');

    const {
        value: commentValue,
        isValid: commentIsValid,
        hasError: commentHasError,
        valueChangeHandler: commentChangedHandler,
        inputBlurHandler: commentBlurHandler,
        reset: resetComment,
    } = useInput((value) => value.trim() !== '');

    let formIsValid = false;

    if (nameIsValid && emailIsValid && subjectIsValid && commentIsValid) {
        formIsValid = true;
    }
    const formSubmissionHandler = (event) => {
        event.preventDefault();

        if (!formIsValid) {
            if (!nameIsValid) {
                //nameSetIsTouched(true);

                console.log('hola' + nameInputClasses);
            }
            return;
        }
        setTextEnviado(!textEnviado);
        setFormEnviado(!formEnviado);

        const contact = {
            name: nameValue,
            email: emailValue,
            subject: subjectValue,
            comment: commentValue,
        };

        addContact(contact);
        console.log('Submitted!');
        resetName();
        resetEmail();
        resetsubject();
        resetComment();
    };
    async function addContact(contact) {
        var body =
            '<html><ul><li>Este email fue enviado por ' +
            contact.name +
            '</li><li>Email: ' +
            contact.email +
            '</li><li>Telefono: ' +
            contact.subject +
            '</li><li>Web: ' +
            contact.comment +
            '</li></ul></html>';

        var datos = {email_subject: 'Petición de contacto desde la web de  Traceparent', body: body};
        const response = await fetch('https://hp5mk81ga0.execute-api.eu-west-3.amazonaws.com/mail', {
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        console.log(data);
    }

    const nameInputClasses = nameHasError ? 'sectionForm_form_name invalid' : 'sectionForm_form_name';

    const emailInputClasses = emailHasError ? 'sectionForm_form_email invalid' : 'sectionForm_form_email';

    const subjectInputClasses = subjectHasError ? 'sectionForm_form_subject invalid' : 'sectionForm_form_subject';

    const commentInputClasses = commentHasError ? 'sectionForm_form_comment invalid' : 'sectionForm_form_comment';

    useState();
    const [textEnviado, setTextEnviado] = useState('true');
    const [formEnviado, setFormEnviado] = useState('true');

    return (
        <Fragment>
            <form className={'sectionForm_form ' + (formEnviado ? '' : 'formActive')} onSubmit={formSubmissionHandler}>
                <div className={nameInputClasses}>
                    <label htmlFor="name">{t('form.name')}</label>
                    <div>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            value={nameValue}
                            onChange={nameChangedHandler}
                            onBlur={nameBlurHandler}
                        />
                        {nameHasError && <span id="errorName"> {t('form.input.noValid')} </span>}
                    </div>
                </div>
                <div className={emailInputClasses}>
                    <label htmlFor="email">{t('form.email')}</label>
                    <div>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            value={emailValue}
                            onChange={emailChangeHandler}
                            onBlur={emailBlurHandler}
                        />
                        {emailHasError && <span id="errorEmail"> {t('form.input.emailNoValid')}</span>}
                    </div>
                </div>
                <div className={subjectInputClasses}>
                    <label>{t('form.subject')}</label>
                    <div>
                        <input
                            type="text"
                            name="subject"
                            id="subject"
                            value={subjectValue}
                            onChange={subjectChangeHandler}
                            onBlur={subjectBlurHandler}
                        />
                        {subjectHasError && <span id="errorSubject"> {t('form.input.noValid')} </span>}
                    </div>
                </div>
                <div className={commentInputClasses}>
                    <label>{t('form.comment')}</label>
                    <div>
                        <textarea
                            name="comment"
                            id="comment"
                            cols="30"
                            rows="8"
                            value={commentValue}
                            onChange={commentChangedHandler}
                            onBlur={commentBlurHandler}
                        />
                        {commentHasError && <span id="errorComment"> {t('form.input.noValid')} </span>}
                    </div>
                </div>
                <div className={'sectionForm_form_submit'}>
                    <input
                        //disabled={!formIsValid}
                        name="submit"
                        type="submit"
                        className={'submit'}
                        value={t('form.submit.botton')}
                        onClick={() => {
                            nameBlurHandler();
                            emailBlurHandler();
                            subjectBlurHandler();
                            commentBlurHandler();
                        }}
                    />
                </div>
                <div className={'textPrivacyBox'}>
                    <NavLink to="/privacy">{t('form.linkPrivacy')}</NavLink>
                </div>
            </form>
            <div className={'boxText ' + (textEnviado ? '' : 'textActivo')}>
                <p>{t('form.submit.text')}</p>
            </div>
        </Fragment>
    );
};

export default Form;
