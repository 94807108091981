import React from 'react';
import styled from 'styled-components';

import styles from './TitleBlock.module.css';

export interface Props {
    colorBack?: string;
    children?: React.ReactNode;
}
const BoxTitle = styled.div``;

export const TitleSectionBox: React.FC<Props> = ({colorBack, children}) => {
    return (
        <>
            <BoxTitle
                className={styles.boxTitle}
                style={{
                    backgroundColor: colorBack ? colorBack : 'transparent',
                }}>
                {children}
            </BoxTitle>
        </>
    );
};
