import {Trans, useTranslation} from 'react-i18next';

import styles from './Contact.module.css';
import {TitleSectionBox} from 'components/Labels/TitleBlock';
import Form from 'components/Form/Form';
import {Color} from 'components/Styles/colors';
import {Title} from 'components/Labels/Label';
import planeTop from 'assets/img/contact_plane_top.svg';
import planeDown from 'assets/img/contact_plane_down.svg';

export const Contact = () => {
    const {t} = useTranslation('global');

    return (
        <section className={styles.contact_section} id="contact">
            <TitleSectionBox colorBack={Color.black}>
                <Title title={<Trans t={t}>contact.title</Trans>} color="white"></Title>
            </TitleSectionBox>
            <div className={styles.contact_mainBox}>
                <h3>
                    <Trans t={t}>contact.box2_title</Trans>
                </h3>
                <Form></Form>
            </div>
            <img src={planeTop} className={styles.imgPlaneTop} alt="Ilustración avión de papel" />
            <img src={planeDown} className={styles.imgPlaneDown} alt="lustración avión de papel" />
        </section>
    );
};
