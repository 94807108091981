import {useEffect} from 'react';

import {HeaderPages} from './components/HeaderPages';
import TextCookies from './components/TextCookies';
import {Footer} from 'components/Footer/Footer';

const Cookies = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <HeaderPages />
            <TextCookies />
            <Footer />
        </>
    );
};
export default Cookies;
