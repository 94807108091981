import React from 'react';
import styled from 'styled-components';

import {Color} from 'components/Styles/colors';
import {Size} from 'components/Styles/size';
export interface Props {
    icon?: string;
    iconAlt?: string;
    title?: string;
    subTitle?: string;
}
const Box = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: ${Size.medium}px;
    padding-bottom: ${Size.medium}px;
    padding-left: ${Size.medium}px;
    padding-right: ${Size.medium}px;
`;
const BoxImg = styled.div`
    padding: 2rem;
`;
const Title = styled.p`
    font-family: urbanist;
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 64px;
    text-align: center;
    color: ${Color.blue};
`;
const SubTitle = styled.p`
    font-family: urbanist;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
`;
export const IconTop: React.FC<Props> = (props) => {
    return (
        <Box>
            <BoxImg>
                <img src={props.icon} alt={props.iconAlt} />
            </BoxImg>
            <Title>{props.title}</Title>
            <SubTitle>{props.subTitle}</SubTitle>
        </Box>
    );
};
