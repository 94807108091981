import {Trans, useTranslation} from 'react-i18next';
import {useMediaQuery} from 'react-responsive';

import styles from './Header.module.css';
import TraceparentLogo from 'assets/img/logo_TraceparentHorizontal.svg';
import {ButtonScrollLink} from 'components/Buttons/Button_ScrollLink';
import flagSpain from 'assets/img/flag_spain.webp';
import flagUnitedKingdom from 'assets/img/flag_unitedKingdom.webp';

export const Header = () => {
    const {t, i18n} = useTranslation('global');
    const isPortrait = useMediaQuery({query: '(orientation: portrait)'});
    const isLandscape = useMediaQuery({query: '(orientation: landscape)'});
    return (
        <section
            className={`${styles.header_section} ${
                isLandscape ? styles.header_section_horizontal : styles.header_section_vertical
            }`}
            id="header">
            <div className={styles.header_logo}>
                <img src={TraceparentLogo} alt="React Logo" />
            </div>
            <div className={styles.nav_language}>
                <img
                    className="header_flag"
                    onClick={() => i18n.changeLanguage('es')}
                    src={flagSpain}
                    alt="Bandera española"></img>
                <img
                    className="header_flag"
                    onClick={() => i18n.changeLanguage('en')}
                    src={flagUnitedKingdom}
                    alt="Bandera Reino Unido"></img>
            </div>
            <div className={styles.header_mainBox}>
                {isLandscape && (
                    <h1>
                        <Trans t={t}>header.title</Trans>
                    </h1>
                )}
                {isPortrait && (
                    <h1>
                        <Trans t={t}>header.title2</Trans>
                    </h1>
                )}
            </div>
            <div className={styles.header_button}>
                <ButtonScrollLink LinkDirection="problem" text={t('header.button')}></ButtonScrollLink>
            </div>
        </section>
    );
};
