import {Header, Problem, Solution, Blockchain, AboutUs, Contact, Download, CookieBanner} from './components';
import {Footer} from 'components/Footer/Footer';

const Welcome = () => {
    return (
        <div>
            <Header />
            <Problem />
            <Solution />
            {/* <Opinion /> */}
            <Blockchain />
            <AboutUs />
            <Contact />
            <Download />
            <Footer />
            <CookieBanner />
        </div>
    );
};
export default Welcome;
