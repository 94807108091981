import React from 'react';

import styles from './Label.module.css';

export interface Props {
    color?: string;
    title?: string;
    align?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
}

export const Title: React.FC<Props> = ({color, title}) => {
    return (
        <>
            <p
                className={styles.title}
                style={{
                    color: color,
                }}>
                {title}
            </p>
        </>
    );
};
export const SubTitle: React.FC<Props> = ({color, title}) => {
    return (
        <>
            <p
                className={styles.subTitle}
                style={{
                    color: color,
                }}>
                {title}
            </p>
        </>
    );
};
export const Text: React.FC<Props> = ({align, color, title}) => {
    return (
        <>
            <p
                className={styles.text}
                style={{
                    color: color,
                    textAlign: align ? align : 'justify',
                }}>
                {title}
            </p>
        </>
    );
};
