import {Trans, useTranslation} from 'react-i18next';

import styles from './Blockchain.module.css';
import {TitleSectionBox} from 'components/Labels/TitleBlock';
import {Color} from 'components/Styles/colors';
import {Title, Text} from 'components/Labels/Label';

export const Blockchain = () => {
    const {t} = useTranslation('global');

    return (
        <section className={styles.blockchain_section} id="blockchain">
            <TitleSectionBox colorBack={Color.yellow}>
                <Title title={t('blockchain.title')}></Title>
            </TitleSectionBox>
            <div className={styles.blockchain_box}>
                <div>
                    <Text title={<Trans t={t}>blockchain.text1</Trans>}></Text>
                    <Text title={<Trans t={t}>blockchain.text2</Trans>}></Text>
                    <div>
                        <Text className={styles.blockchain_label} title={<Trans t={t}>blockchain.label1</Trans>}></Text>
                        <Text className={styles.blockchain_label} title={<Trans t={t}>blockchain.label2</Trans>}></Text>
                        <Text className={styles.blockchain_label} title={<Trans t={t}>blockchain.label3</Trans>}></Text>
                    </div>
                </div>
            </div>
        </section>
    );
};
