import styles from './TextPages.module.css'
import { Trans, useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
function TextPrivacy() {
  const { t } = useTranslation("global");
  return (
    <section className={styles.contentPolicies}>
      <Link                
        to="/"
        spy={true}
        smooth={true}
        offset={-70}
        duration={700} 
        className={styles.nav_nav_link}>
        &#x2190; {t("pages.linkHome")}
      </Link>  
      <h2> <Trans t={t}>privacy.title</Trans></h2>
      <div> <Trans t={t}>privacy.general</Trans></div>
      <div> <Trans t={t}>privacy.principios</Trans></div>
      <div> <Trans t={t}>privacy.tratamientos</Trans></div>
      <div> <Trans t={t}>privacy.derechos</Trans></div>
      <div> <Trans t={t}>privacy.adicional</Trans></div>
    </section>
  );
}
export default TextPrivacy;