import React from 'react';
import styled from 'styled-components';

import styles from './TextBox.module.css';
export interface Props {
    text?: string;
    align?: string;
}
const Box = styled.div`
    display: flex;
    flex-direction: row;
`;
const BoxText = styled.div`
    padding: 2rem;
    background-color: white;
`;
export const TextBox: React.FC<Props> = (props) => {
    return (
        <>
            <Box {...props}>
                <BoxText className={styles.boxText}>
                    <p className={styles.boxText_p}>{props.text}</p>
                </BoxText>
            </Box>
        </>
    );
};
