import {Fragment} from 'react';
import {Route, Routes} from 'react-router-dom';
import {GTMProvider} from '@elgorditosalsero/react-gtm-hook';

import Welcome from 'pages/welcome/Welcome';
import Cookies from 'pages/subPages/Cookies';
import Privacy from 'pages/subPages/Privacy';
import Legality from 'pages/subPages/Legality';

function App() {
    const gtmParams = {id: 'GTM-T9QVG53'};
    return (
        <Fragment>
            <GTMProvider state={gtmParams}>
                <Routes>
                    <Route path="/" exact element={<Welcome />}></Route>
                    <Route path="/privacy" element={<Privacy />}></Route>
                    <Route path="/cookies" element={<Cookies />}></Route>
                    <Route path="/legality" element={<Legality />}></Route>
                </Routes>
            </GTMProvider>
        </Fragment>
    );
}
export default App;
