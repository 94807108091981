import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import styles from './HeaderPages.module.css';
import TraceparentLogoHorizontal from 'assets/img/traceparentLogo_Horizontal.svg';
import flagSpain from 'assets/img/flag_spain.webp';
import flagUnitedKingdom from 'assets/img/flag_unitedKingdom.webp';

export const HeaderPages = () => {
    const {i18n} = useTranslation('global');
    return (
        <div className={styles.nav}>
            <div className={styles.nav_logo}>
                <Link to="/" spy={true} smooth={true} offset={-70} duration={700}>
                    <img src={TraceparentLogoHorizontal} alt="React Logo" className={styles.nav_logo_img} />
                </Link>
            </div>
            <div className={styles.nav_language}>
                <img
                    class="header_flag"
                    onClick={() => i18n.changeLanguage('es')}
                    src={flagSpain}
                    alt="Bandera española"></img>
                <img
                    class="header_flag"
                    onClick={() => i18n.changeLanguage('en')}
                    src={flagUnitedKingdom}
                    alt="Bandera Reino Unido"></img>
            </div>
        </div>
    );
};
