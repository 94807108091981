import {Trans, useTranslation} from 'react-i18next';

import styles from './Download.module.css';
// import AppStoreLogo from 'assets/img/download_logo_appStore.svg';
// import GooglePlayLogo from 'assets/img/download_logo_googlePlay.svg';
import {Text} from 'components/Labels/Label';

export const Download = () => {
    const {t} = useTranslation('global');

    return (
        <section className={styles.download_section} id="download">
            <h3>
                <Trans t={t}>download.title</Trans>
            </h3>
            <Text title={<Trans t={t}>download.text</Trans>} align="center"></Text>
            {/* <div className={styles.download_boxButton}>
                <img src={AppStoreLogo} alt="App Store Logo" />
                <img src={GooglePlayLogo} alt="Google Play Logo" />
            </div> */}
        </section>
    );
};
