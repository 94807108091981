import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useCookies} from 'react-cookie';
import {useEffect, useState} from 'react';

import styles from './CookieBanner.module.css';
import {Button} from 'components/Buttons/Button';
import {Color} from 'components/Styles/colors';
import {Size} from 'components/Styles/size';

export function CookieBanner() {
    const {t} = useTranslation();
    const [cookies, setCookie] = useCookies(['user']);
    const [isOpen, setIsOpen] = useState(true); //Displays Modal
    //const cookies = new Cookies('registered');

    function handleSetCookie() {
        setCookie('user', 'visitante', {path: '/'});
        setIsOpen(false);
    }
    useEffect(() => {
        if (cookies.user === 'visitante') {
            setIsOpen(false); //Modal does not open if cookie exists
        } else {
            setIsOpen(true); //shows modal.
        }
    }, [cookies]);

    return (
        <div style={{display: isOpen ? 'block' : 'none'}}>
            <div className={styles.modalCookies} id="myModal">
                <div className={styles.modalCookies_boxText}>
                    <p>{t('cookiesBanner.text')}</p>
                </div>
                <div className={styles.modalCookies_boxButtons}>
                    <Button
                        colorBack={Color.green}
                        colorText={Color.black}
                        marginRight={Size.small}
                        title={
                            <NavLink
                                to="/"
                                id={styles.closeCookies}
                                onClick={handleSetCookie}
                                style={{
                                    textDecoration: 'none',
                                    color: Color.black,
                                }}>
                                {t('cookiesBanner.accept.button')}
                            </NavLink>
                        }></Button>
                    <Button
                        colorBack={Color.green}
                        colorText={Color.black}
                        title={
                            <NavLink
                                to="/cookies"
                                id={styles.visitCookies}
                                style={{textDecoration: 'none', color: Color.black}}>
                                {' '}
                                {t('cookiesBanner.cookiesPolicy.button')}{' '}
                            </NavLink>
                        }></Button>
                </div>
            </div>
            <div className={styles.overlay}></div>
        </div>
    );
}
