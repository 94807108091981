import {Trans, useTranslation} from 'react-i18next';

import styles from './Solution.module.css';
import {TitleSectionBox} from 'components/Labels/TitleBlock';
import Slider from 'components/Slider/Slider';
import TraceparentLogo from 'assets/img/TraceparentLogo_grande.png';
import {TextBox} from 'components/Labels/TextBox';
import {Title, SubTitle} from 'components/Labels/Label';
import {Color} from 'components/Styles/colors';

export const Solution = () => {
    const {t} = useTranslation('global');

    return (
        <section className={styles.solution_section} id="solution">
            <TitleSectionBox colorBack={Color.green} colorText={Color.black}>
                <Title title={<Trans t={t}>solution.title</Trans>}></Title>
                <SubTitle title={<Trans t={t}>solution.subtitle</Trans>}></SubTitle>
            </TitleSectionBox>
            <div className={styles.solution_boxText}>
                <TextBox
                    text={<Trans t={t}>solution.textBox_1</Trans>}
                    style={{
                        justifyContent: 'flex-end',
                        paddingBottom: '3rem',
                    }}></TextBox>
                <h1>
                    <Trans t={t}>solution.intro.label</Trans>
                </h1>
                <TextBox
                    text={<Trans t={t}>solution.textBox_2</Trans>}
                    style={{
                        justifyContent: 'flex-start',
                        marginTop: '-1rem',
                    }}></TextBox>
            </div>
            <div className={styles.solution_boxSlider}>
                <img src={TraceparentLogo} alt="Traceparent Logo" className={styles.solution_logo} />
                <Slider></Slider>
            </div>
        </section>
    );
};
