import {Trans, useTranslation} from 'react-i18next';

import styles from './AboutUs.module.css';
import {TitleSectionBox} from 'components/Labels/TitleBlock';
import TeralcoLogo from 'assets/img/TeralcoLogo.png';
import InnubaLogo from 'assets/img/InnubaLogo.png';
import CDTILogo from 'assets/img/CDTILogo.png';
import {Color} from 'components/Styles/colors';
import {Title, Text} from 'components/Labels/Label';

export const AboutUs = () => {
    const {t} = useTranslation('global');

    return (
        <section className={styles.aboutUs_section} id="aboutUs">
            <TitleSectionBox colorBack={Color.purple}>
                <Title title={t('aboutUs.title')} color="white"></Title>
            </TitleSectionBox>
            <div className={styles.aboutUs_Box}>
                <div className={styles.aboutUs_BoxRight}></div>
                <div className={styles.aboutUs_BoxLeft}>
                    <Text title={<Trans t={t}>aboutUs.text1</Trans>}></Text>
                    <Text title={<Trans t={t}>aboutUs.text2</Trans>}></Text>
                    <Text title={<Trans t={t}>aboutUs.text3</Trans>}></Text>
                    <div className={styles.aboutUs_BoxLeft_logos}>
                        <img src={TeralcoLogo} alt="Teralco Group Logo" />
                        <img src={InnubaLogo} alt="Innuva Logo" />
                        <img src={CDTILogo} alt="CDTI Logo" />
                    </div>
                </div>
            </div>
        </section>
    );
};
