//import LanguageDetector from 'i18next-browser-languagedetector';
import global_en from "./translations/en/global.json";
import global_es from "./translations/es/global.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

/*Si queremos que el idioma de nuestra aplicación sea el del navegador de manera manual
const userLang = navigator.language || navigator.userLanguage;
const defaultLocale = userLang.substring(0, 2);
Añadir más abajo...
lng: defaultLocale
*/
const userLang = navigator.language || navigator.userLanguage;
const defaultLocale = userLang.substring(0,2);
i18n
//.use(LanguageDetector)
.use(initReactI18next)
  .init(
    {
      // lng: "es", 
      /* Al utilizar LanguageDetector inutilizamos el lng:"es" o lng:"dafaultLocale", */
      lng: defaultLocale,
      interpolation: {
        escapeValue: false,
        formatSeparator: ",",
      },
      fallbackLng: "en",
      resources: {
        es: {
          global: global_es
        },
        en: {
          global: global_en
        }
      },
      defaultNS: 'global',
      // react i18next special options (optional)
      // override if needed - omit if ok with defaults
      react: {
        bindI18n: 'languageChanged',
        bindI18nStore: '',
        transEmptyNodeValue: '',
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['h3', 'h6', 'ul', 'li', 'span', 'a', 'br', 'u', 'strong', 'i', 'b', 'p', 'table', 'th', 'td', 'td', 'tr'],
        useSuspense: true,
      }
    },


  );

export default i18n;