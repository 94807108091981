import styles from './TextPages.module.css';
import {Trans, useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

function TextCookies() {
    const {t} = useTranslation('global');
    return (
        <section className={styles.contentPolicies}>
            <Link to="/" spy={true} smooth={true} offset={-70} duration={700} className={styles.nav_nav_link}>
                &#x2190; {t('pages.linkHome')}
            </Link>
            <h2>
                <Trans t={t}>cookies.title</Trans>
            </h2>
            <div>
                <Trans t={t}>cookies.text.0</Trans>
            </div>
            <div>
                <Trans t={t}>cookies.cookiesUse</Trans>
            </div>
            <div>
                <Trans t={t}>cookies.cookiesType</Trans>
            </div>
            <div>
                <Trans t={t}>cookies.cookiesConsent</Trans>
            </div>
            <div>
                <Trans t={t}>cookies.cookiesRemove</Trans>
            </div>
            <div>
                <Trans t={t}>cookies.cookiesModifications</Trans>
            </div>
            <div>
                <Trans t={t}>cookies.cookiesTable1</Trans>
            </div>
            <div>
                <Trans t={t}>cookies.cookiesTable2</Trans>
            </div>
        </section>
    );
}

export default TextCookies;
