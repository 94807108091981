import {useEffect} from 'react';

import {HeaderPages} from './components/HeaderPages';
import TextLegality from './components/TextLegality';
import {Footer} from 'components/Footer/Footer';

const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <HeaderPages />
            <TextLegality />
            <Footer />
        </>
    );
};
export default Privacy;
