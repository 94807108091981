import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
//import {FaFacebookF, FaInstagram, FaYoutube, FaTwitter} from 'react-icons/fa';

import styles from './Footer.module.css';
import TraceparentLogoSmall from 'assets/img/traceparentLogoSmall.svg';

export const Footer = () => {
    const {t} = useTranslation('global');

    return (
        <section className={styles.footer_section} id="footer">
            <div className={styles.footer_logo}>
                <img className={styles.footer_logo_img} src={TraceparentLogoSmall} alt="Traceoarent Logo" />
            </div>
            <div className={styles.footer_nav}>
                <NavLink to="/cookies" className={styles.footer_link}>
                    {t('footer.cookies.label')}
                </NavLink>
                <NavLink to="/privacy" className={styles.footer_link}>
                    {t('footer.privacy.label')}
                </NavLink>
                <NavLink to="/legality" className={styles.footer_link}>
                    {t('footer.legalWarning.label')}
                </NavLink>
            </div>
            <div className={styles.footer_text}>
                <p>+34 965 688 702</p>
                <p>info@traceparent.com</p>
                <p>C/ Severo Ochoa 46, planta 2, 03203, Elche, Alicante</p>
                {/* <div className={styles.footer_rrss}>
                    <a href="https://es-es.facebook.com" target="_blank" rel="noreferrer">
                        <FaFacebookF />
                    </a>
                    <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
                        <FaInstagram />
                    </a>
                    <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
                        <FaYoutube />
                    </a>
                    <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
                        <FaTwitter />
                    </a>
                </div> */}
                <p className={styles.footer_text_copyright}>
                    {new Date().getFullYear()} {t('footer.rights.text')}
                </p>
            </div>
        </section>
    );
};
