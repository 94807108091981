import {Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Trans, useTranslation} from 'react-i18next';

import './../Slider/Slider.css';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';

import LaptopImg from 'assets/img/laptop.png';
import PhoneMockupFullImg from 'assets/img/phoneMockup.svg';
import LaptopMockupFullImg from 'assets/img/laptopMockup.svg';

export default function App() {
    const {t} = useTranslation('global');
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={0}
                pagination={{
                    clickable: true,
                }}
                breakpoints={{
                    640: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                    },
                    1024: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                    },
                }}
                modules={[Pagination]}
                className="mySwiper">
                <SwiperSlide>
                    {' '}
                    <div className="sliderBox">
                        <div className="sliderBox_imgLeft">
                            <img src={PhoneMockupFullImg} alt="React Logo" />
                        </div>
                        <div className="sliderBox_imgCenter">
                            <img src={LaptopImg} alt="React Logo" />
                        </div>
                        <div className="sliderBox_imgRight">
                            <img src={LaptopMockupFullImg} alt="React Logo" />
                        </div>
                        <div className="sliderBox_imgCenter_text">
                            <p>{<Trans t={t}>solution.textSlider1</Trans>}</p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    {' '}
                    <div className="sliderBox">
                        <div className="sliderBox_imgLeft"></div>
                        <div className="sliderBox_imgCenter">
                            <img src={PhoneMockupFullImg} alt="React Logo" />
                        </div>
                        <div className="sliderBox_imgRight">
                            <img src={LaptopImg} alt="React Logo" />
                        </div>
                        <div className="sliderBox_imgCenter_text">
                            <p>{<Trans t={t}>solution.textSlider2</Trans>}</p>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    {' '}
                    <div className="sliderBox">
                        <div className="sliderBox_imgLeft"></div>
                        <div className="sliderBox_imgCenter">
                            <img src={LaptopMockupFullImg} alt="React Logo" />
                        </div>
                        <div className="sliderBox_imgRight">
                            <img src={PhoneMockupFullImg} alt="React Logo" />
                        </div>
                        <div className="sliderBox_imgCenter_text">
                            <p>{<Trans t={t}>solution.textSlider3</Trans>}</p>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </>
    );
}
