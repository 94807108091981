import styles from './TextPages.module.css';
import {Trans, useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
function TextPrivacy() {
    const {t} = useTranslation('global');
    return (
        <section className={styles.contentPolicies}>
            <Link to="/" spy={true} smooth={true} offset={-70} duration={700} className={styles.nav_nav_link}>
                &#x2190; {t('pages.linkHome')}
            </Link>
            <h2>
                {' '}
                <Trans t={t}>legality.title</Trans>
            </h2>
            <div>
                {' '}
                <Trans t={t}>legality.subTitle</Trans>
            </div>
            <div>
                {' '}
                <Trans t={t}>legality.text</Trans>
            </div>
            <div>
                {' '}
                <Trans t={t}>legality.data</Trans>
            </div>
            <div>
                {' '}
                <Trans t={t}>legality.ambit</Trans>
            </div>
            <div>
                {' '}
                <Trans t={t}>legality.access</Trans>
            </div>
            <div>
                {' '}
                <Trans t={t}>legality.rights</Trans>
            </div>
            <div>
                {' '}
                <Trans t={t}>legality.utilization.web</Trans>
            </div>
            {/* <div> <Trans t={t}>legality.utilization.blog</Trans></div> */}
            <div>
                {' '}
                <Trans t={t}>legality.communications</Trans>
            </div>
            <div>
                {' '}
                <Trans t={t}>legality.responsibilities</Trans>
            </div>
            <div>
                {' '}
                <Trans t={t}>legality.links</Trans>
            </div>
            <div>
                {' '}
                <Trans t={t}>legality.links2</Trans>
            </div>
            <div>
                {' '}
                <Trans t={t}>legality.rrss</Trans>
            </div>
            <div>
                {' '}
                <Trans t={t}>legality.cookies</Trans>
            </div>
            <div>
                {' '}
                <Trans t={t}>legality.privacy</Trans>
            </div>
            <div>
                {' '}
                <Trans t={t}>legality.modification</Trans>
            </div>
            <div>
                {' '}
                <Trans t={t}>legality.observations</Trans>
            </div>
            <div>
                {' '}
                <Trans t={t}>legality.legislation</Trans>
            </div>
            <div>
                {' '}
                <Trans t={t}>legality.responsible</Trans>
            </div>
        </section>
    );
}
export default TextPrivacy;
