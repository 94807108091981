import {Trans, useTranslation} from 'react-i18next';

import styles from './Problem.module.css';
import {TitleSectionBox} from 'components/Labels/TitleBlock';
import PlasticosImg from 'assets/img/plasticos.png';
import GraphImg from 'assets/img/graph.png';
import FactoryIcon from 'assets/icons/factory.svg';
import CraneIcon from 'assets/icons/crane.svg';
import OilIcon from 'assets/icons/oil.svg';
import {Color} from 'components/Styles/colors';
import {IconTop} from 'components/Block/Box_IconTop';
import {Title, SubTitle, Text} from 'components/Labels/Label';

export const Problem = () => {
    const {t} = useTranslation('global');

    return (
        <section className={styles.problem_section} id="problem">
            <TitleSectionBox colorBack={Color.blue}>
                <Title title={t('problem.title')}></Title>
                <SubTitle title={t('problem.subTitle')}></SubTitle>
            </TitleSectionBox>
            <div className={styles.problem_box}>
                <img src={PlasticosImg} alt="React Logo" />
            </div>
            <div className={styles.problem_box2}>
                <h2>
                    <Trans t={t}>problem.text</Trans>
                </h2>
                <h3>
                    <Trans t={t}>problem.subText</Trans>
                </h3>
                <div className={styles.problem_box2_float}>
                    <IconTop icon={FactoryIcon} iconAlt="React Logo" title="30%" subTitle={t('problem.Icon1.Text')} />

                    <IconTop icon={CraneIcon} iconAlt="React Logo" title="27%" subTitle={t('problem.Icon2.Text')} />

                    <IconTop icon={OilIcon} iconAlt="React Logo" title="43%" subTitle={t('problem.Icon3.Text')} />
                </div>
            </div>
            <div className={styles.problem_box3}>
                <div className={styles.problem_box3_left}>
                    <Text title={<Trans t={t}>problem.paragraph1</Trans>} />
                    <Text title={<Trans t={t}>problem.paragraph2</Trans>} />
                    <Text title={<Trans t={t}>problem.paragraph3</Trans>} />
                </div>
                <div className={styles.problem_box3_right}>
                    <img src={GraphImg} alt="React Logo" />
                </div>
            </div>
        </section>
    );
};
