export interface ColorsInterface {
    black: string;
    white: string;
    green: string;
    purple: string;
    yellow: string;
    blue: string;
    gray: string;
}

export const Color: ColorsInterface = {
    black: '#000000',
    white: '#FFFFFF',
    green: '#00E798',
    purple: '#734AFE',
    yellow: '#FFD932',
    blue: '#0077FF',
    gray: '#ECEAE4',
};
